import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { fetchAll, fetchSingle } from "services/get";
import { post } from "services/post";
import {
  CreateEcartIntegrationRequestDto,
  CreateIntegrationRequestDto,
  CreateShopifyIntegrationRequestDto,
} from "./dtos/create-integration.request.dto";
import { PatchIntegrationRequestDto } from "./dtos/patch-integration.request.dto";
import {
  FetchEcartResponseDto,
  FetchIntegrationsResponseDto,
  FetchShopifyResponseDto,
} from "./dtos/fetch-integrations.response.dto";
import { FetchSingleIntegrationCredentialsDto } from "./dtos/fetch-single-integration.response.dto";
import { patch } from "services/patch";
import {
  EbayIntegrationBody,
  IIntegration,
  ISalesAccountIntegration,
  ShipstationIntegrationBody,
} from "models/integration/integration.model";

export const fetchIntegrations = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<FetchIntegrationsResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.integrations
  );
};

export const fetchIntegrationBySlug = async (
  salesAccountUUID: ISalesAccount["uuid"],
  slug: string
): Promise<FetchSingleIntegrationCredentialsDto> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/" +
      slug
  );
};

export const createIntegration = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateIntegrationRequestDto
): Promise<FetchIntegrationsResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.integrations,
    values
  );
};

export const createEcartIntegration = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateEcartIntegrationRequestDto
): Promise<FetchEcartResponseDto> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/post/ecart",
    values
  );
};

export const fetchActiveEcartIntegrations = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<any> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/get/ecart"
  );
};

export const updateIntegration = async (
  integrationAccount: ISalesAccountIntegration,
  values: PatchIntegrationRequestDto,
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<FetchShopifyResponseDto> => {
  console.log(values);
  return await patch(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/" +
      integrationAccount.id,
    values
  );
};

export const verifyShopify = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateShopifyIntegrationRequestDto
): Promise<FetchShopifyResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.integrations,
    values
  );
};

export const setShipstationIntergrationStatus = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: IIntegration
): Promise<FetchShopifyResponseDto> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/shipstation/status",
    values
  );
};

export const setEbayIntergrationStatus = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: IIntegration
): Promise<FetchShopifyResponseDto> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/ebay/status",
    values
  );
};

export const createShipstationIntergration = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: ShipstationIntegrationBody
): Promise<FetchShopifyResponseDto> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/shipstation/create",
    values
  );
};

export const createEbayIntergration = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: EbayIntegrationBody
): Promise<FetchShopifyResponseDto> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.integrations +
      "/ebay/create",
    values
  );
};

export const fetchOrders = async (
  salesAccountUUID: ISalesAccount["uuid"],
  storeUUID: string
): Promise<{ statusCode: number; message: string; error: boolean }> => {
  const { statusCode, message, error } = await fetchSingle(
    `${customerApiBase}/${salesAccountUUID}${apiPaths.integrations}/fetch-orders/${storeUUID}`
  );
  return { statusCode, message, error };
};
