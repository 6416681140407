export const customerApiBase = "/sales-accounts";

export enum apiPaths {
  "fetchMe" = "/users/me",
  "quotes" = "/quotes",
  "addresses" = "/addresses",
  "settings" = "/settings",
  "companyAddresses" = "/company-addresses",
  "companyContacts" = "/company-contacts",
  "rules" = "/rules",
  "shipments" = "/shipments",
  "labelJobs" = "/label-jobs",
  "combinePdfs" = "/combine-pdfs",
  "combinePdfsBase64" = "/combine-pdfs/base64",
  "commercialInvoiceBase64" = "/combine-pdfs/commercial-invoice-base64",
  "virtualWarehouses" = "/virtual-warehouses",
  "shippingWhitelists" = "/shipping-whitelists",
  "bulkCancelShipments" = "/shipments/bulk-delete",
  "pickups" = "/pickups",
  "bulkShipments" = "/shipment-batch-jobs",
  "integrations" = "/integrations",
  "bulkShipmentsFormatValidate" = "/shipment-batch-jobs/format-validate-shipments",
  "shipmentProfiles" = "/shipment-import-profiles",
  "pusher" = "/pusher",
  "singleSalesAccount" = "/single-sales-account",
  "changelogs" = "/changelogs",
}

export enum apiAdminPaths {
  "salesAccounts" = "/admin/sales-accounts",
  "users" = "/admin/users",
  "carriers" = "/admin/carriers",
  "quotes" = "/admin/quotes",
  "sendQuotesEmail" = "/admin/quotes/send-email",
}

export enum apiPublicPaths {
  "webforms" = "/public/webforms",
  "countries" = "/public/countries",
  "currencies" = "/public/currencies",
}

export enum paths {
  "ADDRESS_BOOK_SETTINGS" = "/settings/address-book",
  "BULK_SHIPMENTS_INDEX" = "/bulk-shipments",
  "COMPANY_SETTINGS_GENERAL" = "/settings/company",
  "COMPANY_SETTINGS_DEFAULTS" = "/settings/company/defaults",
  "COMPANY_SETTINGS_SHIPPING_LANES" = "/settings/company/shipping-lanes",
  "COMPANY_SETTINGS_COMPANY_ADDRESSES" = "/settings/company/company-addresses",
  "DASHBOARD_INDEX" = "/",
  "LABELS_INDEX" = "/settings/labels",
  "LOGIN_INDEX" = "/login",
  "PICKUPS_INDEX" = "/pickups",
  "PRINTING_SETTINGS" = "/settings/printing",
  "QUOTES_INDEX" = "/quotes",
  "RULE_SETTINGS" = "/settings/rules",
  "SETTINGS_INTEGRATIONS_INDEX" = "/settings/integrations",
  "SHIPMENTS_INDEX" = "/",
  "SHIPMENT_PROFILES_SETTINGS" = "/settings/shipment-profiles",
  "SETTINGS_VIRTUAL_WAREHOUSES_INDEX" = "/settings/virtual-warehouses",
  "CHANGELOG_SETTINGS" = "/settings/changelogs",
  "DOCUMENTATIONS" = "/documentations",
}
